.App {
  text-align: center;
}

.App-logo {
  /*height: 40vmin;*/
  /*pointer-events: none;*/

width:400px;

  /*top:50%;*/
  /*left:50%;*/
  /*margin-top:-50px;*/
  /*margin-left:-100px;*/
}
.center_me{
  position: fixed;
  display: inline-block;
  margin: 0 auto;
  width: 100%;
  top: 25%;
}
/*.close{*/
/*  position: relative;*/
/*  display: inline-block;*/
/*  margin: 0 auto;*/
/*  width: 75%;*/
/*  top: 25%;*/
/*  right: 15%;*/
/*}*/


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
